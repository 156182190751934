import React from 'react'
import { graphql } from 'gatsby'
import { PageContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import ResumeReviewPage from '@talentinc/gatsby-theme-ecom/pages/top/ResumeReviewPage'
import pageData from '../data/cv-review'

type PageProps = {
  pageContext: PageContextType
}

export default function Page({ pageContext }: PageProps) {
  return <ResumeReviewPage pageData={pageData} pageContext={pageContext} />
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
